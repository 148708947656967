.PageCollections {

  &--collections {
    box-sizing: border-box;
    list-style: none;
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    padding: 0 20px;
    display: block;
    position: relative;
  }

}