@import "./variables";

@mixin desktop() {
  @media screen and (min-width: $width--medium-screen) {
    @content;
  }
}

@mixin tablet-up() {
  @media screen and (min-width: $width--mobile-screen) {
    @content;
  }
}

@mixin tablet-only() {
  @media screen and (max-width: $width--medium-screen) and (min-width: $width--mobile-screen) {
    @content;
  }
}

@mixin tablet-down() {
  @media screen and (max-width: $width--mobile-screen) {
    @content;
  }
}

@mixin mobile() {
  @media screen and (max-width: $width--mobile-screen) {
    @content;
  }
}