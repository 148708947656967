@import '../../styles/partials/media';

.Intro {

  &--root {
    p {
      font-size: 24px;
      font-weight: 400;
      line-height: 1.2;
      margin-top: 0;
      margin-bottom: 15px;

      &:last-child {
        margin: 0;
      }
    }

  }

}