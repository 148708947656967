@import "../../styles/partials/variables";

.Screen {

  &--root {
    box-sizing: border-box;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    padding-top: 60px;
    padding-bottom: 0;
    margin-top: -60px;
    padding-bottom: 60px;
    margin-bottom: -60px;
    min-height: 100vh;
    width: 100%;
  }

}