@import '../../styles/partials/variables';
@import '../../styles/partials/media';

.Page {

  &--root {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    padding: 60px 0;
  }

  &--header {
    box-sizing: border-box;
    padding: 0 20px;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
  }

  &--title {
    display: block;
    margin-bottom: 30px;
  }

  &--intro {
    display: block;
    margin-bottom: 30px;
  }

  &--body {
    box-sizing: border-box;
    display: block;
    margin: 0 auto;
    margin-bottom: 30px;
    max-width: 1000px;
    width: 100%;
    padding: 0 20px;

    h1, h2, h3, h4, h5, h6 {
      font-weight: 500;
      font-family: $font--heading;
      margin-top: 0;
    }

    p {
      line-height: 1.6em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &--image {
    max-width: 600px;
    margin: 20px auto;
  }

  &--image-content {
    position: relative;
    width: 100%;
    height: auto;

    @include tablet-up {
      border-radius: 5px;
    }
  }
  
}