@import '../../../lib/styles/partials/media';

.PageCollection {

  &--items {
    box-sizing: border-box;
    list-style: none;
    margin: 30px auto;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 60px;
    justify-items: center;
    align-items: baseline;
    text-align: center;
  }

  &--item {
    box-sizing: border-box;
    max-width: 100%;
    flex: 1 0 auto;

    @include desktop {
      width: 30%;
      max-width: 400px;
    }
  }
}