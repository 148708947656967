@import '../../styles/partials/media';

.Header {

  &--root {
    display: block;
  }

  &--container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    max-width: 1000px;
    margin: auto;
    padding: 0 20px;

    @include mobile {
      padding-right: 10px;
    }
  }

  &--logo {
    flex: 0 0 auto;
    margin-left: 0;
    margin-right: auto;
  }

  &--menu-buttom {
    flex: 0 0 auto;
    margin-left: auto;
    margin-right: 0;
  }

}