@import '../../styles/partials/variables';

.Footer {

  &--root {
    background-color: $color--white;
    display: block;
    line-height: 20px;
    position: relative;
  }

  &--container {
    box-sizing: border-box;
    display: block;
    line-height: 20px;
    max-width: 1000px;
    margin: auto;
    padding: 20px;
    position: relative;
  }

  &--copyright {
    letter-spacing: 1px;
    line-height: 20px;
    font-size: 12px;
  }

}