@import '../../styles/partials/media';

.Heading {

  &--primary {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.1;
    margin: 0;
    padding: 0;

    @include desktop {
      font-size: 48px;
    }
  }

  &--secondary {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.1;
    margin: 0;
    padding: 0;
  }

  &--section {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.1;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
  }

}