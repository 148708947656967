@import '../../../lib/styles/partials/variables';
@import '../../../lib/styles/partials/media';

.Painting {

  &--root {
    position: relative;
  }

  &--image-container {
    box-shadow: 0 10px 60px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    line-height: 0;
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
    cursor: pointer;
  }

  &--image {
    background-color: $tint--light;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }

  &--image.Painting--image-loaded {
    position: relative;
    width: 100%;
    height: auto;
    padding-bottom: 0;
  }

  &--inquire {
    border-radius: 5px;
    background-color: $color--white;
    color: $color--black;
    position: absolute;
    line-height: 1;
    left: 10px;
    right: 10px;
    bottom: 10px;
    padding: 20px;
  }

  &--info {
    display: block;
  }

  &--sold {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $color--error;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &--details {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: center;
  }

  &--title {
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    line-height: 20px;
    font-weight: normal;
    font-style: italic;
    font-size: 20px;
    position: relative;
  }

  &--dimensions {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
  }

  &--purchase {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  &--modal-contents {
    display: block;
    flex: 0 0 auto;
  }
}