.Logo {

  &--root {
    color: inherit;
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    padding: 20px 0;
    text-decoration: none;
    white-space: nowrap;
  }

  &:hover,
  &:active,
  &:visited {
    color: inherit;
  }

}