@import '../../styles/partials/variables';
@import '../../styles/partials/media';

.Button {
 
  &--button {
    border: 1px solid $color--black;
    border-radius: 20px;
    cursor: pointer;
    display: inline-block;
    font-family: $font--body;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1;
    position: relative;
    padding: 10px 15px;
    transition: all 300ms;

    &:focus {
      outline: none;
    }
  }

  &--button.Button--ready {
    background-color: $color--white;
    color: $color--black;

    &:hover {
      box-shadow: $shadow--drop;
      transform: scale(1.07);
    }

    &:active, &:disabled {
      background-color: $color--white;
      box-shadow: 0 0 0 0 $tint--light;
      border-color: $tint--dark;
      color: $tint--dark;
      cursor: default;
      transform: translate(0);
    }
  }

  &--button.Button--running {
    background-color: $color--white;
    box-shadow: none;
    border-color: $tint--dark;
    color: $tint--dark;
    transform: scaleX(1) scaleY(1);
  }
  
  &--button.Button--failed {
    background-color: $color--white;
    box-shadow: none;
    border-color: $color--error;
    color: $color--error;
    transform: scaleX(1) scaleY(1);
  }
  
  &--button.Button--succeeded {
    background-color: $color--success;
    box-shadow: $shadow--outset-highlight;
    color: $color--white;
    border-color: rgba(0, 0, 0, 0.3);
    transform: scaleX(1) scaleY(1);
  }

  &--progress {
    background-color: $color--medium;
    border-radius: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    transition: all 300ms;
    z-index: -1;
  }
  
  &--button.Button--ready &--progress {
    display: none;
  }

  &--link {
    color: $color--black;
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: inherit;
    position: relative;
    transition: all 300ms;

    &:hover {
      color: $color--medium;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: calc(100% + 5px);
      width: 100%;
      height: 1px;
      transition: all 300ms;
      background-color: $color--black;
    }

    &:hover::after {
      width: 0%;
    }
  }

  &--icon {
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-family: $font--body;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 20px;
    height: 60px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    transition: all 500ms;

    &:hover {
      color: $color--medium;
      transform: scale(1.1);
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  &--icon::before {
    display: block;
    font-family: 'Material Icons';
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }

  &--icon-menu::before {
    content: '\e5d3';
  }

  &--icon-close::before {
    content: '\e5cd';
  }

  &--icon-done::before {
    content: '\e5ca';
  }
}