@import '../../styles/partials/variables';

.Menu {

  &--root {
    align-items: stretch;
    background-color: $tint--dark;
    display: flex;
    opacity: 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    transition: all 300ms 300ms, z-index 0ms 600ms;
    visibility: hidden;
    z-index: -999;
  }

  &--open {
    opacity: 1;
    transition: all 300ms, z-index 0ms;
    visibility: visible;
    z-index: 999;
  }

  &--drawer {
    background-color: $color--white;
    color: $color--black;
    box-shadow: $shadow--drop;
    max-width: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    transition: transform 300ms;
    width: 90vw;
  }

  &--right {
    margin-right: 0;
    margin-left: auto;
    transform: translateX(400px);
  }

  &--left {
    margin-left: 0;
    margin-right: auto;
    transform: translateX(-400px);
  }

  &--open &--drawer {
    transform: translateX(0);
  }

  &--contents {
    padding: 15px;
  }

}