@import "../../../lib/styles/partials/variables";
@import "../../../lib/styles/partials/media";

.PageHome {

  &--content {
    box-sizing: border-box;
    display: block;
    max-width: 100%;
    margin: auto;
    padding: 60px 20px;

    @include tablet-up {
      max-width: 40vw;
    }

    @include desktop {
      max-width: 30vw;
    }
  }

  &--button {
    margin-top: 60px;
    text-align: center;
  }

}