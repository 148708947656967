.MenuList {

  &--root {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &--item {
    margin-bottom: 10px;
    padding: 10px;
  }

}