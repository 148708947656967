$color--white: #FFFFFF;
$color--black: #000000;
$color--gray: #EFEFEF;
$color--bright: #ffe614;
$color--medium: #2757d7;
$color--medium-dark: #2f438b;
$color--dark: #222222;
$color--error: #f42834;
$color--success: $color--medium;

$opacity--light: 0.05;
$opacity--medium: 0.1;
$opacity--dark: 0.3;
$opacity--black: 0.7;

$tint--light: rgba(0, 0, 0, $opacity--light);
$tint--medium: rgba(0, 0, 0, $opacity--medium);
$tint--dark: rgba(0, 0, 0, $opacity--dark);
$tint--black: rgba(0, 0, 0, $opacity--black);

$shadow--inset-highlight: inset 0 3px 0 $color--bright;
$shadow--outset-highlight: 0 0 0 5px $color--bright;
$shadow--drop: 0 2px 50px 10px $tint--medium;
$shadow--drop-tilt: 0 15px 25px 5px $tint--medium, 0 30px 50px 5px $tint--medium, 0 60px 70px 5px $tint--medium;

$font--body: "Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",'Helvetica Neue',Helvetica,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
$font--heading: $font--body;
$font--forms: "DM Mono", monospace;

$font-size--body: 16px;
$font-weight--body: 400;

$width--large-screen: 1200px;
$width--medium-screen: 900px;
$width--mobile-screen: 600px;