@import '../../../lib/styles/partials/variables';
@import '../../../lib/styles/partials/media';

.CollectionCard {
  &--root {
    display: block;
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &--link {
    display: block;
    position: relative;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: black;
    color: $color--white;
    padding: 30px;
    overflow: hidden;
    box-shadow: 0 0 0 0 transparent;

    @include desktop {
      padding: 60px;
    }

    &:hover {
      color: $color--white;
      transform: translateY(-5px);
      box-shadow: 0 10px 60px 20px rgba(0, 0, 0, 0.1);

      .CollectionCard--background::after {
        background-position-x: 0%;
      }
    }
  }

  &--background {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 100%);
      background-size: 200%;
      background-position-x: 50%;
      transition: background-position-x 0.5s;
    }
  }

  &--title {
    margin-bottom: 20px;
  }

  &--title, &--description {
    position: relative;
    z-index: 2;

    @include desktop {
      max-width: 70%;
    }
  }
}