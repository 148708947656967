@import '../../styles/partials/variables';

.Modal {

  &--root {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparentize($color: $color--white, $amount: 0.5);
    transition: all 0.3s;
    z-index: -9999;
  }

  &--open {
    visibility: visible;
    opacity: 1;
    z-index: 9999;
  }
  
}